import React from 'react';
import {  Route ,Redirect } from "react-router-dom";

function ProtectedRoute({isAuth : isAuth , subname ,component : Component , ...rest}) {

    return <Route 
    {...rest} 

    render={(props)=>{
        if(isAuth){
            return <Component {...props} subname={subname}/>
        }
        else{
            return (
                <Redirect to={{pathname:'/unauthorized' , state:{from : props.location}}}/>
            )
        }
    }}/>
}

export default ProtectedRoute;
