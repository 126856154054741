import React,{ Fragment ,useContext} from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import { UserContext } from './context/UserContext'
import Logo from './assets/logoWithoutWriting.png'
const navigation = [
  { name: 'Home', href: '/home'},
  { name: 'Courses', href: '/course'},
  {name : 'Exam Prep' , href:'/subject/sample'},
  // { name: 'Special Offer', href: '/specialoffer'}
]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar({addBottomBorder=false}) {
  const [userdetail ,setUserdetail] = useContext(UserContext)


  return (
    <Disclosure as="nav" className={`bg-gray-800 ${addBottomBorder?"border-4 border-gray-900 border-t-0 border-l-0 border-r-0":" "}`}>
      {({ open }) => (
        <div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
      
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-12 w-auto "
                    src={Logo}
                    alt="selectin"
                  />
                  {/* "https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg" */}
                  <img
                    className="hidden lg:block h-12"
                    src={Logo}
                    alt="selectin"
                  />
                  <p className="hidden lg:block text-gray-100 text-2xl ml-2">Selectin</p>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.href === window.location.pathname? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md text-lg font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
               

                {/* Profile dropdown */}
               {/* {userdetail.isSignedIn ? <LoggedCommponent userName={userdetail.name} photo={userdetail.photo} status={userdetail.status}/> : <MenuComponent/>} */}
               
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.href === window.location.pathname? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}


const MenuComponent = () =>{
  return(
    <Menu as="div" className="ml-3 relative">
    {({ open }) => (
      <div>
        <div>
          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none">
            <span className="sr-only">Open user menu</span>
            {/* <img
              className="h-8 w-8 rounded-full"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/24px-Google_%22G%22_Logo.svg.png"
              alt=""
            /> */}
            <button class="bg-blue-500 hover:bg-blue-700 text-white text-xs sm:text-lg font-bold py-2 px-4 rounded font-serif">Sign in</button>
          </Menu.Button>
        </div>
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <Menu.Item>
              {({ active }) => (
                <a
                  href="https://api.selectin.in/auth"
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                >
                  Log in / Sign Up
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </div>
    )}
  </Menu>

  )
}



const LoggedCommponent = ({userName , photo,status}) =>{
  return(
    <Menu as="div" className="ml-3 relative">
    {({ open }) => (
      <div>
        <div>
          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span className="sr-only">Open user menu</span>
            <img
              className="h-8 w-8 rounded-full"
              src={photo}
              alt=""
            />
          </Menu.Button>
        </div>
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <Menu.Item>
              {({ active }) => (
                <span
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                >
                  {userName}
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {status?<div className="block px-4 py-2 text-sm text-green-700">activated</div> : <div className="block px-4 py-2 text-sm text-red-700">deactivated</div>}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="https://api.selectin.in/logout"
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                >
                  log out
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </div>
    )}
  </Menu>

  )
}
