import React from "react";
import Logo from '../assets/logoWithoutWriting.png'
import { Link } from "react-router-dom";
function Footer() {
    return (
        <>
            <div className=" bg-linear-pink-invert pb-12">
                <div className="mx-auto container pt-5 lg:pt-10 flex flex-col items-center justify-center">

                    <div className="pt-5 flex flex-col justify-center items-center">
                        
                        <img
                        className="lg:block h-20 w-auto"
                        src={Logo}
                        alt="selectin"
                        />
                        
                    </div>

                        <div className="text-black flex flex-col md:items-center f-f-l pt-3">
                        
                            <div className="my-6 text-base text-color f-f-l">
                                <ul className="md:flex items-center">
                                    <li className=" md:mr-6 cursor-pointer pt-4 lg:py-0">selectinofficial@gmail.com</li>
                                    <li className=" md:mr-6 cursor-pointer pt-4 lg:py-0">Contact +91 8123008103</li>
                                   {/* <li>
                                   <Link to="/specialoffer" className="md:mr-6 cursor-pointer pt-4 lg:py-0">Pricing</Link>
                                    </li>  */}
                                    <li>
                                    <Link to="/aboutus" className=" md:mr-6 cursor-pointer pt-4 lg:py-0">About us</Link>
                                    </li>
                                    <li>
                                    <Link to="/policy" className=" md:mr-6 cursor-pointer pt-4 lg:py-0">Privacy Policy</Link>
                                    </li>
                                    <li>
                                    <Link to="/terms" className=" md:mr-6 cursor-pointer pt-4 lg:py-0">Terms and condition</Link>
                                    </li>
                                    <li>
                                    <Link to="/refund" className=" md:mr-6 cursor-pointer pt-4 lg:py-0">Refund Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        <p className="mt-6 text-xs lg:text-sm leading-none text-gray-900 dark:text-gray-50">© 2021 selectin. All Rights Reserved.</p>
                   
                    </div>
               
                    
                
                </div>
            </div>
        </>
    );
}

export default Footer;
