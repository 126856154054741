import React  from 'react';
import './index.css'

const Banner = () =>{
    return(
        <div className=" max-h-6xl pb-5 mt-5 ml-4 sm:ml-0">
        {/* <div className="sm:grid grid-cols-1 md:grid grid-cols-3"> */}
            <div className="flex flex-col">
                <h1 style={{color:"#002D62"}} className="text-5xl  text-center sm:text-left mb-3 sm:text-8xl italic font-serif">Selectin</h1>
                <div className="text-lg sm:text-4xl font-serif">We are a team of distinguished and dedicated doctors committed to the service of all medical students.</div>
            </div>
            <div> </div>
        {/* <img src="https://codeforcause.org/static/home/codeforcause.svg"/> */}
        {/* </div> */}
        </div>
    )
}

export default Banner