import React from 'react';
import Banner from '../Banner';
import Navbar from '../Navbar';
import '../index.css'
import Footer from '../components/Footer';
import { Link } from 'react-router-dom'
import exampImage from '../assets/poster3.png'
import PosterImage from '../assets/poster.png'
function Home() {

 

  return (
    <div>
        <Navbar/>
       <div className="xl:mx-96">
            <Banner/>
               
          

            <div className="mb-20 sm:mb-32">
                <div style={{textTransform: 'capitalize' ,color:"#002D62"}} className="text-2xl italic font-serif sm:text-5xl text-center mt-5">Exam preparation guide</div>
                <div className="text-center mt-6">
                    <Link to="/subject/sample">
                         <div class="flex flex-col justify-center items-center">
                             <img alt="exam" src={exampImage} width={300} height={1000}/>
                         </div>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-serif py-2 px-3 text-2xl rounded-full mt-6">30 Days Prep</button>
                    </Link>
                </div>
            </div>


             <div className="mb-20 sm:mb-32">
                <div style={{textTransform: 'capitalize' ,color:"#002D62"}} className="text-2xl italic font-serif sm:text-5xl text-center mt-5">MBBS Phase-1 (CBME)-RS4</div>
                <div className="text-center mt-6">
                    <Link to="/course">
                         <div class="flex flex-col justify-center items-center">
                             <img alt="exam" src={PosterImage} width={200} height={500}/>
                         </div>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-serif py-2 px-3 text-2xl rounded-full mt-6">Complete Syllabus</button>
                    </Link>
                </div>
            </div>



            <div>
                {/* <div style={{textTransform: 'capitalize' ,color:"#002D62"}} className="text-3xl italic font-serif sm:text-5xl  mt-5 mb-9 text-center">Courses we offer</div> */}
                  
                {/* <div style={{textTransform: 'capitalize' ,color:"#002D62"}} className=" sm:bg-gray-300 sm:flex sm:flex-row sm:justify-around  mt-5">
                    <div className="bg-gray-300 font-serif mt-2 mb-2 py-3 sm:mt-0 sm:mb-0 text-2xl text-center sm:text-4xl sm:text-left">MBBS Phase - 1 (CBME) - RS4</div>

                    <div className="text-center sm:py-3">
                        <Link to="/course">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-serif px-3 py-2 text-2xl rounded-full">complete syllabus</button>
                        </Link>
                    </div>    
                 </div>

                  {/* <Courses phase={2}/>    
                  <Courses phase={3}/>  
                  <Courses phase={4}/>   */}
                </div> 
            <div>
             
             {/* <div className="text-3xl sm:text-5xl text-blue-600 mt-10 sm:mt-32">
                Special Offers
             </div> */}
            {/* <SpecialOfferCard duration={6} amount={750} discountedAmount={399} lessThanCost={2}/> */}


        <div className="mt-12 sm:mb-16 ml-4 sm:ml-0">

<div style={{textTransform: 'capitalize' ,color:"#002D62"}} className="text-3xl italic font-serif sm:text-5xl">
    how to use selectin :
</div> 
<div className="text-1xl mt-3 sm:text-2xl sm:leading-normal m-2 ml-0">
<span style={{fontFamily:"sans-serif" ,fontWeight:'bold'}}>Step 1)</span> <span className="font-serif">Read the whole concept of a topic in depth and understand it.</span>
</div>

<div  className="text-1xl sm:text-2xl sm:leading-normal m-2 ml-0 font-serif">
<span style={{fontFamily:"sans-serif" ,fontWeight:'bold'}}>Step 2)</span> <span > Open the questions related to the topic in the selectin.
Make sure to understand the concept behind the question and the reason behind that question.</span>
</div>

<div  className="text-1xl sm:text-2xl sm:leading-normal m-2 ml-0 font-serif">
<span style={{fontFamily:"sans-serif" ,fontWeight:'bold'}}>Step 3) </span> 
<span  >
     Write the answers in the form of an essay.
    Use techniques like "spaced repetition" to revise the subject efficiently.
</span> 
</div>


<div className="text-1xl sm:text-2xl sm:leading-normal m-2 ml-0 font-serif">
Read each topic of all the subjects consistently and at the end, you will be confident enough to attend all the questions in your exam without any fear.
</div>    
</div>


            <div className=" bg-green-500 xl:flex flex-row justify-around p-5 mt-5">
                <h3 className="text-white text-center font-serif  mt-2 mb-2  sm:mt-0 sm:mb-0 text-2xl sm:text-4xl">Latest CBME Competency based Questions by Experienced Faculty</h3>
            </div>
       
            </div>
        </div>


        

        <div className="mt-20">
            <Footer/>
        </div>
    </div>
  );
}

const Courses = ({phase}) => {
    return(
        <div className="sm:bg-gray-300 sm:flex sm:flex-row sm:justify-around sm:items-baseline mt-5">
        <div style={{textTransform: 'capitalize' ,color:"#002D62"}} className="text-gray-100 bg-gray-300 font-serif mt-2 mb-2 py-3 sm:mt-0 sm:mb-0 text-2xl sm:text-4xl text-center sm:text-left">MBBS Phase - {phase} (CBME) - RS4</div>

        <div className="text-center sm:py-3">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-serif  py-2 px-3 text-2xl rounded-full opacity-50 cursor-not-allowed">coming soon</button>
        </div>    
     </div>
    )
}

const SpecialOfferCard = ({duration , amount ,discountedAmount ,lessThanCost}) =>{
    return(
        
        
          <div class="bg-indigo-50 mt-4 mb-4 px-2 sm:px-0">
           
            <div class="text-red-500 font-serif text-xl sm:text-4xl mb-2 text-center">Limited Time special offer</div>
            <p class="text-gray-700 text-base sm:text-2xl">Get access to   more variety of questions for Anatomy, Physiology, Biochemistry for <span className="text-xl underline text-red-500">less then Rs 2 a day </span>
             for <span className="text-xl underline text-red-500"> 6 months</span></p>
            <div className="text-center">
            <span className="text-xl line-through mr-4 sm:text-2xl">Rs {amount}</span>
            <span className="text-red-500 text-3xl sm:text-4xl">Rs {discountedAmount}</span>
            </div>
         <div className="text-center mt-4">
         <Link to="/specialoffer">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-serif  py-2 px-3 text-lg rounded mb-5 sm:text-4xl">Buy now</button>
          </Link>
         </div>
          </div>
    )
}

export default Home;
