import './App.css';
import React, { useContext ,useEffect ,Suspense} from 'react';
import { BrowserRouter, Route ,Redirect} from "react-router-dom";
import Question from './pages/Question';
import {UserContext} from './context/UserContext'
import ProtectedRoute from './pages/ProtectedRoute';
import {ACTIVATEDURL} from './assets/apiUrl/index'
import Home from './pages/Home'
import axios from 'axios'
const Loading = () => (
  <div>
    Loading ...
  </div>
)

const RedirectToHome = () =>{
  return(
    <Redirect to={{pathname:'/home'}}/>
  )
}

const Course = React.lazy(() => import('./pages/Course'))
const Policy = React.lazy(() => import('./pages/Policy'))
const RefundPolicy = React.lazy(() => import('./pages/RefundPolicy'))
const TermsAndCondition = React.lazy(()=> import('./pages/TermsAndCondition'))
const SubjectPage = React.lazy(()=>import('./pages/SubjectPage'))
// const SpecialOffer = React.lazy(() => import('./pages/SpecialOffer'))
const SamplePage = React.lazy(()=> import('./pages/sample/SamplePage'))
const SampleSubjectPage = React.lazy(() => import('./pages/sample/SampleSubjectPage'))
const UnAuthorized = React.lazy(() => import('./pages/UnAuthorized'))
const SampleQuestionPage = React.lazy(() => import('./pages/sample/SampleQuestionPage'))
const AboutUs = React.lazy(() => import('./pages/AboutUs'))

function App() {
const [userdetail ,setUserdetail] = useContext(UserContext)

  useEffect(async () =>{
    // console.log("calling")
  //   const fetchActivation = () =>{
  //     return fetch(ACTIVATEDURL,{credentials: 'include'})
  //     .then(response => {return response.json()})
  //     .then(data => {
  //       if(data.name){

  //         setUserdetail(prevstate => ({...prevstate,isSignedIn:true}))
  //       }
  //       if(data.name){
    
  //         setUserdetail(prevstate => ({...prevstate,name:data.name}))
  //       }
  //       if(data.photo){
    
  //         setUserdetail(prevstate => ({...prevstate,photo:data.photo}))
  //       }
  //       if(data.status){
      
  //         setUserdetail(prevstate => ({...prevstate,status:data.status}))
  //       }
  //     })
  //     .catch(error =>{
  //       console.log(error)
  //     })
  //   }
  //  fetchActivation()
     
    // fetch("https://geolocation-db.com/json")
    // // fetch("https://pokeapi.co/api/v2/pokemon/ditto")
    // .then(response => {return response.json()})
    // .then(data => console.log(data))
    // .catch(err =>{
    //   console.log(err)
    // })

       try {

         const res = await axios.get('https://selectin.herokuapp.com/api/user')
         console.log(res.data);
        
    
       } catch (error) {
         console.log(error)
       }
  },[])

  return (
    <div>
      <BrowserRouter>
        <Route exact path="/" component={RedirectToHome}/>
        <Route exact path="/home" component={Home}/>
        <Suspense fallback={<Loading/>}>
        
        <Route exact path="/course" component={Course}/>
        <Route exact path="/aboutus" component={AboutUs}/>
        <Route exact path="/policy" component={Policy}/>
        <Route exact path="/terms" component={TermsAndCondition}/>
        <Route exact path="/refund" component={RefundPolicy}/>
        {/* <Route exact path="/specialoffer" component={SpecialOffer}/> */}

        <Route exact path="/subject/sample/physiology"  component={props => <SampleSubjectPage subname="physiology" {...props}/>}/>
        <Route exact path="/subject/sample/anatomy"  component={props => <SampleSubjectPage subname="anatomy" {...props}/>}/>
        <Route exact path="/subject/sample/biochemistry"  component={props => <SampleSubjectPage subname="biochemistry" {...props}/>}/>
        <Route expact path="/subject/sample/question" component={SampleQuestionPage}/>

        <Route exact path="/unauthorized" component={UnAuthorized}/>
        <Route exact path="/subject/sample" component={SamplePage}/>
        <ProtectedRoute path="/subject/anatomy" component={SubjectPage} subname="anatomy" isAuth={true}/>
        <ProtectedRoute path="/subject/physiology" component={SubjectPage} subname="physiology" isAuth={true}/>
        <ProtectedRoute path="/subject/biochemistry" component={SubjectPage} subname="biochemistry" isAuth={true}/>
        <ProtectedRoute path="/question" component={Question} isAuth={true}/>
    </Suspense>
       
   
      </BrowserRouter>
    </div>
  );
}


export default App;

