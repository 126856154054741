import React, { useState, createContext} from 'react';

export const UserContext = createContext()

export const UserProvider = props =>{
    const [userdetail , setUserdetail] = useState(false)

    return(
        <UserContext.Provider value={[userdetail,setUserdetail]}>
        {props.children}
        </UserContext.Provider>
    )

}